// company components
import CompanyDefaultDataColumn from '../../../components/CompaniesTableColumns/staticColumns/CompanyDefaultDataColumn/CompanyDefaultDataColumn';

// local lib
import generateColumn from '../../../../table/lib/generateColumn.lib.table';

// string lib
import sortByString from '../../../../string/lib/sortByString.lib.string';

export default generateColumn({
  title: 'Financial Status',
  componentProps: ({company}) => {
    const status = !!company?.Financials?.FinancialStatus
      ? company?.Financials?.FinancialStatus.split('/').join('\n')
      : '-';
    return {
      children: status,
    };
  },
  Component: CompanyDefaultDataColumn,
  sorter: (a, b) => {
    const valueA = a?.Financials?.FinancialStatus || '';
    const valueB = b?.Financials?.FinancialStatus || '';
    return sortByString({valueA, valueB});
  },
});
