// company components
import HighlightedGrade from '../../../components/HighlightedGrade/HighlightedGrade';

// local lib
import generateColumn from '../../../../table/lib/generateColumn.lib.table';

export default generateColumn({
  title: 'Halo ESG Factor Rating',
  componentProps: ({company}) => ({
    score: Number(company?.ESGCSR?.HaloESGFactorRating),
  }),
  Component: HighlightedGrade,
  sorter: (a, b) => {
    return (
      Number(a?.ESGCSR?.HaloESGFactorRating || 0) -
      Number(b?.ESGCSR?.HaloESGFactorRating || 0)
    );
  },
});
