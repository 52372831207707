// ant components
import {Table} from 'antd';

// local lib
import generateColumns from './lib/generateColumns.lib';

// react
import React from 'react';

// redux
import {useSelector} from 'react-redux';

// tenant lib
import prepareCustomTenantDataForTable from '../../lib/prepareCustomDataForTable.lib.tenant';

const CustomData = () => {
  const {company, customData} = useSelector((state) => ({
    company: state.company.company,
    customData: state.tenant.customData,
  }));

  const blackKiteId = company?.BlackKiteID || company?.BlackKiteId;
  const companyId = company?.CompanyId;

  const columns = generateColumns();
  const data = prepareCustomTenantDataForTable(
    customData?.[companyId] || customData?.[blackKiteId] || {}
  );

  return (
    <Table
      columns={columns}
      dataSource={data}
      pagination={false}
      rowKey="entry"
      size="small"
      showHeader={false}
    />
  );
};

export default CustomData;
