// company components
import CompanyDefaultDataColumn from '../../../components/CompaniesTableColumns/staticColumns/CompanyDefaultDataColumn/CompanyDefaultDataColumn';

// local lib
import generateColumn from '../../../../table/lib/generateColumn.lib.table';

// string lib
import sortByString from '../../../../string/lib/sortByString.lib.string';

export default generateColumn({
  title: 'Also Known As',
  componentProps: ({company}) => ({
    children: company?.Firmographic?.AlsoKnownAs || '-',
  }),
  Component: CompanyDefaultDataColumn,
  sorter: (a, b) => {
    const valueA = a?.Firmographic?.AlsoKnownAs || '';
    const valueB = b?.Firmographic?.AlsoKnownAs || '';
    return sortByString({valueA, valueB});
  },
});
