// collection api
import getCollectionDataApi from '../api/getData.api.collection';
import getCollectionCompaniesApi from '../api/getCompanies.api.collection';

// data lib
import prepareData from '../../data/lib/prepare.lib.data';

const getCollectionDataService = async ({
  collection,
  getCollectionData = getCollectionDataApi,
  getCollectionCompanies = getCollectionCompaniesApi,
}) => {
  const collectionId = (
    !collection || collection?.name === 'Portfolio'
      ? 'portfolio'
      : collection?.id
  ).replace('collection_', '');
  const {data: suppliers} = await (!!collection
    ? getCollectionCompanies(collection?.id)
    : {data: []});
  const collectionData = await getCollectionData(collectionId);
  const {historicHaloScores, haloScores} = prepareData({
    scores: collectionData?.HaloPortfolioScores,
  });

  return {
    ...collectionData,
    suppliers,
    HaloScores: haloScores,
    historicHaloScores,
  };
};

export default getCollectionDataService;
