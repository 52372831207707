// company lib
import generateCompanyFilter from '../lib/generateFilter.lib.company';

export default generateCompanyFilter({
  filter: 'tag',
  label: 'Tags',
  fn: ({entry, filterValue, tags: allTags}) => {
    const tags = filterValue || [];
    const companyTags = [...allTags].filter(
      (tag) =>
        !!tag.company_ids.find(
          (companyTagId) =>
            companyTagId.replace('company_', '') === entry.CompanyId
        )
    );
    // return (
    //   !tags.length || tags.every((tag) => entry.tags.find(({id}) => id === tag))
    // );
    return (
      !tags.length || tags.some((tag) => companyTags.find(({id}) => id === tag))
    );
  },
  props: {
    mode: 'multiple',
    filterOption: (input, option) =>
      (option?.label ?? '').toLowerCase().includes(input.toLowerCase()),
  },
});
