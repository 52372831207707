// company components
import CompanyDefaultDataColumn from '../../../components/CompaniesTableColumns/staticColumns/CompanyDefaultDataColumn/CompanyDefaultDataColumn';
import CompanyDescriptionColumn from '../../../components/CompaniesTableColumns/dynamicColumns/CompanyDescriptionColumn/CompanyDescriptionColumn';

// local lib
import generateColumn from '../../../../table/lib/generateColumn.lib.table';

// string lib
import sortByString from '../../../../string/lib/sortByString.lib.string';

export default generateColumn({
  title: 'Long Description',
  width: 500,
  componentProps: ({company}) => ({
    children: (
      <CompanyDescriptionColumn
        description={company?.Firmographic?.LongDescription}
      />
    ),
  }),
  Component: CompanyDefaultDataColumn,
  sorter: (a, b) => {
    const valueA = a?.Firmographic?.LongDescription || '';
    const valueB = b?.Firmographic?.LongDescription || '';
    return sortByString({valueA, valueB});
  },
});
