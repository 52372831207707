// company components
import CompanyDefaultDataColumn from '../../../components/CompaniesTableColumns/staticColumns/CompanyDefaultDataColumn/CompanyDefaultDataColumn';
import CompanyFilterColumn from '../../../components/CompanyFilterColumn/CompanyFilterColumn';

// company filters
import industriesFilter from '../../../filters/industries.filter.company';

// local lib
import generateColumn from '../../../../table/lib/generateColumn.lib.table';

// string lib
import sortByString from '../../../../string/lib/sortByString.lib.string';

export default generateColumn({
  title: 'Primary Industry',
  componentProps: ({company}) => ({
    children: (
      <CompanyFilterColumn
        filter={industriesFilter.filter}
        filterValue={company?.Firmographic?.PrimaryIndustry || null}
      >
        {company?.Firmographic?.PrimaryIndustry || '-'}
      </CompanyFilterColumn>
    ),
  }),
  Component: CompanyDefaultDataColumn,
  sorter: (a, b) => {
    const valueA = a?.Firmographic?.PrimaryIndustry || '';
    const valueB = b?.Firmographic?.PrimaryIndustry || '';
    return sortByString({valueA, valueB});
  },
});
