// antd
import {
  Table,
  Select,
  Row,
  Col,
  Menu,
  Button,
  Space,
  Input,
  Dropdown,
} from 'antd';

// ant icons
import {SearchOutlined} from '@ant-design/icons';

// file components
// import DownloadJsonButton from '../../../file/components/DownloadJsonButton/DownloadJsonButton';

// layout components
import PageLayout from '../../../layout/components/PageLayout/PageLayout';

// local components
import Container from './components/Container';
import FilterLabel from './components/FilterLabel';
import Filters from './components/Filters';
import MonitoringSuppliers from './components/MonitoringSuppliers';
import Search from './components/Search';
import TableActionsRow from './components/TableActionsRow';
import TableContainer from './components/TableContainer';
import Title from './components/Title';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

// supplier components
// import UploadSuppliersButton from '../../../supplier/components/UploadSuppliersButton/UploadSuppliersButton';

// tag events
import showCreateTagModalEvent from '../../../tag/events/showCreateModal.event.tag';

const CompaniesTable = ({
  availableColumns,
  columns,
  compiledFilterOptions,
  customColumns,
  dataSource,
  filterOptions,
  filters,
  numberOfSuppliers,
  onCustomColumn,
  onFilter,
  onFilterReset,
  onSearch,
  onView,
  search,
  showCustomColumnsSelector,
  tableSize,
  view,
  viewOptions,
}) => (
  <PageLayout>
    {({Content}) => (
      <Content
        title="Suppliers"
        moreButton={
          <Space>
            {/* <UploadSuppliersButton /> */}
            {/* <DownloadJsonButton data={dataSource} fileName="Suppliers List">
              Download visible as JSON
            </DownloadJsonButton> */}
          </Space>
        }
      >
        <Container>
          {/* <Filters>
            <Row gutter={[8, 8]}>
              <Col span={24}>
                <Title>Search</Title>
              </Col>
              <Col span={24}>
                <Input
                  style={{width: '100%'}}
                  onChange={onSearch}
                  value={search}
                  placeholder="Insert supplier name"
                />
              </Col>
            </Row>
          </Filters> */}
          <Filters noShadow>
            <Row gutter={[8, 8]}>
              <Col span={24}>
                <Title>Curated Views</Title>
              </Col>
              <Col span={24}>
                <Menu
                  onClick={onView}
                  selectedKeys={[view]}
                  mode="horizontal"
                  items={viewOptions}
                />
              </Col>
              {showCustomColumnsSelector && (
                <Col span={24}>
                  <Row gutter={[8, 8]}>
                    <Col span={24}>
                      <Title>Column Selector</Title>
                    </Col>
                    <Col span={24}>
                      <Select
                        mode="multiple"
                        size="small"
                        allowClear
                        style={{width: '100%'}}
                        placeholder="Please select columns to display"
                        value={customColumns}
                        onChange={onCustomColumn}
                        options={availableColumns}
                      />
                    </Col>
                  </Row>
                </Col>
              )}
            </Row>
          </Filters>
          <Filters noShadow>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Title>Filter</Title>
              </Col>
              {Object.values(filterOptions).map((filterOption) => (
                <Col span={3} key={filterOption.filter}>
                  <FilterLabel>{filterOption.label}</FilterLabel>
                  <Select
                    size="small"
                    style={{width: '100%'}}
                    onChange={onFilter(filterOption.filter)}
                    value={
                      filters?.[filterOption.filter] ||
                      (filterOption?.props?.mode === 'multiple' ? [] : '')
                    }
                    options={
                      compiledFilterOptions?.[filterOption.filter] ||
                      filterOption.options
                    }
                    allowClear
                    {...filterOption.props}
                  />
                </Col>
              ))}
              <Col span={3}>
                <FilterLabel></FilterLabel>
                <Dropdown
                  trigger={['click']}
                  menu={{
                    onClick: (option) => {
                      if (option.key === 'add_for_all_suppliers_in_list')
                        return showCreateTagModalEvent.publish({
                          supplierIds: [...dataSource].map(
                            ({CompanyId}) => CompanyId
                          ),
                        });

                      if (option.key === 'resetFilters') return onFilterReset();
                    },
                    items: [
                      Object.entries(filters).some(
                        ([filterKey, filterValue]) =>
                          filterKey !== 'collection' &&
                          filterValue !== undefined
                      ) && {
                        label: 'Reset Filters',
                        key: 'resetFilters',
                      },
                      {
                        label: 'Tags',
                        key: 'tags',
                        children: [
                          {
                            key: 'add_for_all_suppliers_in_list',
                            label: 'Create Tag for all Suppliers in this list',
                          },
                        ],
                      },
                    ].filter((item) => item),
                  }}
                >
                  <Button size="small">Actions</Button>
                </Dropdown>
              </Col>
            </Row>
          </Filters>
          <TableContainer>
            <TableActionsRow>
              <Search>
                <Input
                  style={{width: '100%'}}
                  onChange={onSearch}
                  value={search}
                  placeholder="Search"
                  bordered={false}
                  suffix={<SearchOutlined />}
                />
              </Search>
              <MonitoringSuppliers>
                {dataSource.length} Suppliers in this list
              </MonitoringSuppliers>
            </TableActionsRow>
            <Table
              columns={columns}
              dataSource={dataSource}
              rowKey="CompanyId"
              scroll={tableSize}
              size="small"
            />
          </TableContainer>
        </Container>
      </Content>
    )}
  </PageLayout>
);

CompaniesTable.propTypes = {
  availableColumns: PropTypes.array,
  columns: PropTypes.array,
  compiledFilterOptions: PropTypes.object,
  customColumns: PropTypes.array,
  dataSource: PropTypes.array,
  filterOptions: PropTypes.object,
  filters: PropTypes.object,
  numberOfSuppliers: PropTypes.number,
  onCustomColumn: PropTypes.func,
  onFilter: PropTypes.func,
  onFilterReset: PropTypes.func,
  onSearch: PropTypes.func,
  onView: PropTypes.func,
  search: PropTypes.string,
  showCustomColumnsSelector: PropTypes.bool,
  tableSize: PropTypes.object,
  view: PropTypes.string,
  viewOptions: PropTypes.array,
};

export default CompaniesTable;
