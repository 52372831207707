import styled from 'styled-components';

// layout colors
import whiteColor from '../../../../../../layout/colors/white.color.layout';

const Tag = styled.div`
  background-color: ${(props) => props.color};
  border-radius: 4px;
  font-size: 10px;
  padding: 2px;
  color: ${whiteColor};
`;

export default Tag;
