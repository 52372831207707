// company components
import CompanyDefaultDataColumn from '../../../components/CompaniesTableColumns/staticColumns/CompanyDefaultDataColumn/CompanyDefaultDataColumn';

// local lib
import generateColumn from '../../../../table/lib/generateColumn.lib.table';

// string lib
import sortByString from '../../../../string/lib/sortByString.lib.string';

export default generateColumn({
  title: 'Operates in Sanctioned Country',
  componentProps: ({company}) => {
    const operatesInSanctionedCountry =
      company?.Sanctions?.OperatesInSanctionedCountry;
    const undefinedValue = '—';
    const parsedOperatesInSanctionedCountry = [
      null,
      undefined,
      '',
      '-',
    ].includes(operatesInSanctionedCountry)
      ? undefinedValue
      : operatesInSanctionedCountry;
    return {
      children: parsedOperatesInSanctionedCountry || undefinedValue,
    };
  },
  Component: CompanyDefaultDataColumn,
  sorter: (a, b) => {
    const valueA = a?.Sanctions?.OperatesInSanctionedCountry || '';
    const valueB = b?.Sanctions?.OperatesInSanctionedCountry || '';
    return sortByString({valueA, valueB});
  },
});
