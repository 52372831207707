// company components
import HighlightedScore from '../../../components/HighlightedScore/HighlightedScore';

// local lib
import generateColumn from '../../../../table/lib/generateColumn.lib.table';

export default generateColumn({
  title: 'Economic Factor Rating',
  componentProps: ({company}) => ({
    score: Number(company?.GeopoliticalEconomics?.EconomicFactorRating),
  }),
  Component: HighlightedScore,
  sorter: (a, b) => {
    return (
      Number(a?.GeopoliticalEconomics?.EconomicFactorRating || 0) -
      Number(b?.GeopoliticalEconomics?.EconomicFactorRating || 0)
    );
  },
});
