// event HOCs
import subscriptionHOC from '../../../event/hoc/subscription.hoc.event';

// propTypes
import PropTypes from 'prop-types';

// react
import {Component} from 'react';

// react redux
import {connect} from 'react-redux';

// tag api
import listTagsApi from '../../api/list.api.tag';

// tag events
import tagCreatedEvent from '../../events/created.event.tag';
// import tagDeletedEvent from '../../events/deleted.event.tag';
// import tagUpdatedEvent from '../../events/updated.event.tag';

// tag redux events
import {setGeneralTagData as setGeneralTagDataAction} from '../../redux/reducer.redux.tag';

class TagsLoaderContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    subscribe: PropTypes.func,
  };

  componentDidMount() {
    this.loadTags();
    this.props.subscribe(tagCreatedEvent.subscribe(this.addTag));
  }

  loadTags = async () => {
    const {dispatch} = this.props;

    dispatch(setGeneralTagDataAction({loadingTags: true}));

    try {
      const {data: tags} = await listTagsApi({
        include_company_ids: true,
        is_active: true,
      });
      dispatch(setGeneralTagDataAction({loadingTags: false, tags}));
    } catch (error) {
      dispatch(setGeneralTagDataAction({loadingTags: false, tags: []}));
    }
  };

  addTag = (tag) => {
    const {dispatch} = this.props;
    dispatch(setGeneralTagDataAction({tags: [tag, ...this.props.tags]}));
  };

  updateTag = (tag) => {
    const {dispatch} = this.props;
    dispatch(
      setGeneralTagDataAction({
        tags: this.props.tags.map((t) => (t.id === tag.id ? tag : t)),
      })
    );
  };

  deleteTag = (tag) => {
    const {dispatch} = this.props;
    dispatch(
      setGeneralTagDataAction({
        tags: this.props.tags.filter((t) => t.id !== tag.id),
      })
    );
  };

  render() {
    return null;
  }
}

export default connect((state) => ({tags: state.tag.tags}))(
  subscriptionHOC(TagsLoaderContainer)
);
