// company components
import CompanyDefaultDataColumn from '../../../components/CompaniesTableColumns/staticColumns/CompanyDefaultDataColumn/CompanyDefaultDataColumn';
import CompanyFilterColumn from '../../../components/CompanyFilterColumn/CompanyFilterColumn';

// company filters
import stateFilter from '../../../filters/state.filter.company';

// local lib
import generateColumn from '../../../../table/lib/generateColumn.lib.table';

// string lib
import sortByString from '../../../../string/lib/sortByString.lib.string';

export default generateColumn({
  title: 'HQ State',
  componentProps: ({company}) => ({
    children: (
      <CompanyFilterColumn
        filter={stateFilter.filter}
        filterValue={company?.Firmographic?.HQState || null}
      >
        {company?.Firmographic?.HQState || '-'}
      </CompanyFilterColumn>
    ),
  }),
  Component: CompanyDefaultDataColumn,
  sorter: (a, b) => {
    const valueA = a?.Firmographic?.HQState || '';
    const valueB = b?.Firmographic?.HQState || '';
    return sortByString({valueA, valueB});
  },
});
