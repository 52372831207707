// company components
import CompanyDefaultDataColumn from '../../../components/CompaniesTableColumns/staticColumns/CompanyDefaultDataColumn/CompanyDefaultDataColumn';

// local lib
import generateColumn from '../../../../table/lib/generateColumn.lib.table';

export default generateColumn({
  title: 'No. of Relatives & Close Associates (RCAs)',
  componentProps: ({company}) => ({
    children:
      company?.Sanctions?.['No.ofRelativesCloseAssociates(RCAs)'] || '-',
  }),
  Component: CompanyDefaultDataColumn,
  sorter: (a, b) => {
    return (
      Number(a?.Sanctions?.['No.ofRelativesCloseAssociates(RCAs)'] || 0) -
      Number(b?.Sanctions?.['No.ofRelativesCloseAssociates(RCAs)'] || 0)
    );
  },
});
