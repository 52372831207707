// company filters
import complianceRiskLevel from '../filters/complianceRiskLevel.filter.company';
import country from '../filters/country.filter.company';
// import cyberRiskRating from '../filters/cyberRiskRating.filter.company';
// import cyberRiskTags from '../filters/cyberRiskTags.filter.company';
// import diversity from '../filters/diversity.filter.company';
import collection from '../filters/collection.filter.company';
import financialMetricsRating from '../filters/financialMetricsRating.filter.company';
import financialRiskLevel from '../filters/financialRiskLevel.filter.company';
import haloIntrinsicRiskLevel from '../filters/haloIntrinsicRiskLevel.filter.company';
import industries from '../filters/industries.filter.company';
import operationalRiskLevel from '../filters/operationalRiskLevel.filter.company';
import ownership from '../filters/ownership.filter.company';
import reputationalRiskLevel from '../filters/reputationalRiskLevel.filter.company';
import state from '../filters/state.filter.company';
// import status from '../filters/status.filter.company';
import strategicRiskLevel from '../filters/strategicRiskLevel.filter.company';
import tag from '../filters/tag.filter.company';

const listCompanyFilters = () => ({
  collection,
  tag,
  ownership,
  PrimaryIndustry: industries,
  haloIntrinsicRiskLevel,
  operationalRiskLevel,
  financialRiskLevel,
  financialMetricsRating,
  complianceRiskLevel,
  reputationalRiskLevel,
  strategicRiskLevel,
  // cyberRiskRating,
  // cyberRiskTags,
  country,
  state,
  // status,
  // diversity,
});

export default listCompanyFilters;
