import styled from 'styled-components';

// layout colors
import blackColor from '../../../../../../layout/colors/black.color.layout';

// router
import {Link as LinkElement} from 'react-router-dom';

const Link = styled(LinkElement)`
  width: 200px;
  display: flex;
  color: ${blackColor};
  gap: 10px;
`;

export default Link;
