// collection events
import collectionSelectedEvent from '../../../collection/events/selected.event.collection';

// company api
// import listCompaniesApi from '../../api/list.api.company';

// company components
import CompaniesTable from '../../components/CompaniesTable/CompaniesTable';

// company events
import filterCompaniesEvent from '../../events/filter.event.company';

// company lib
import extractCountries from '../../lib/extractCountries.lib.company';
import extractCyberRiskTags from '../../lib/extractCyberRiskTags.lib.company';
import extractDiversities from '../../lib/extractDiversities.lib.company';
import extractIndustries from '../../lib/extractIndustries.lib.company';
import extractStates from '../../lib/extractStates.lib.company';
import listCompanyFilters from '../../lib/listFilters.lib.company';
import prepareSuppliersList from '../../lib/prepareSuppliersList.lib.company';

// company routes
import companyRoute from '../../pages/CompanyPage/route';

// company services
import getUserColumnsService from '../../services/getUserColumns.service.company';
import saveUserColumnsService from '../../services/saveUserColumns.service.company';

// collection lib
import getCollectionOptions from '../../../collection/lib/getCollectionOptions.lib.collection';

// event HOCs
import subscriptionHOC from '../../../event/hoc/subscription.hoc.event';

// layout components
import FullScreenLoader from '../../../layout/components/FullScreenLoader/FullScreenLoader';

// local columnGroups
// import complianceColumnGroup from './columnGroups/compliance.columnGroup';
// import creditPostureColumnGroup from './columnGroups/creditPosture.columnGroup';
import customColumnGroup from './columnGroups/custom.columnGroup';
import customDataColumnGroup from './columnGroups/customData.columnGroup';
// import cyberSecurityColumnGroup from './columnGroups/cyberSecurity.columnGroup';
import defaultColumnGroup from './columnGroups/default.columnGroup';
import esgCsrColumnGroup from './columnGroups/esgCsr.columnGroup';
import financialsColumnGroup from './columnGroups/financials.columnGroup';
import firmographicColumnGroup from './columnGroups/firmographic.columnGroup';
import geopoliticalEconomicsColumnGroup from './columnGroups/geopoliticalEconomics.columnGroup';
// import managementDueDiligenceColumnGroup from './columnGroups/managementDueDiligence.columnGroup';
import sanctionsColumnGroup from './columnGroups/sanctions.columnGroup';
import socialMediaColumnGroup from './columnGroups/socialMedia.columnGroup';

// local lib
import getAllAvailableColumnOptions from './lib/getAllAvailableColumnOptions.lib';
import getSelectedColumns from './lib/getSelectedColumns.lib';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

// redux
import {connect} from 'react-redux';

// route
import withNavigationHOC from '../../../route/hocs/withNavigation.hoc.route';

class CompaniesTableContainer extends Component {
  static propTypes = {
    customData: PropTypes.object,
    collection: PropTypes.object,
    collections: PropTypes.array,
    loadingCollection: PropTypes.bool,
    collectionsData: PropTypes.object,
    navigate: PropTypes.func,
    subscribe: PropTypes.func,
    suppliers: PropTypes.array,
    tenantData: PropTypes.object,
  };

  static columnGroups = [
    defaultColumnGroup,
    firmographicColumnGroup,
    financialsColumnGroup,
    // creditPostureColumnGroup,
    // managementDueDiligenceColumnGroup,
    // cyberSecurityColumnGroup,
    // complianceColumnGroup,
    sanctionsColumnGroup,
    esgCsrColumnGroup,
    geopoliticalEconomicsColumnGroup,
    socialMediaColumnGroup,
    customDataColumnGroup,
    customColumnGroup,
  ];

  state = {
    companies: [],
    customColumns: [],
    filters: {},
    loading: true,
    search: '',
    view: defaultColumnGroup.key,
  };

  componentDidMount() {
    this.mounted = true;
    this.initialize();
    this.getCompanies();
    this.props.subscribe(filterCompaniesEvent.subscribe(this.filterCompanies));
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  compiledFilterOptions = {};

  initialize = () => {
    const {collection} = this.props;
    this.setState({
      filters: {
        collection: collection?.id,
      },
    });
  };

  getCompanies = async () => {
    const {customData, tags, tenantData, suppliers} = this.props;

    try {
      const companies = prepareSuppliersList({
        companies: suppliers,
        customData,
        supplierScores: tenantData?.SupplierHaloScores,
        tenantData: tenantData?.BlackKiteSupplierTenantData,
      });
      this.compiledFilterOptions = {
        collection: getCollectionOptions({withPortfolio: false}),
        country: extractCountries(companies),
        cyberRiskTags: extractCyberRiskTags(companies),
        diversity: extractDiversities(companies),
        industries: extractIndustries(companies),
        state: extractStates(companies),
        tag: [...tags].map(({id, name}) => ({value: id, label: name})),
      };
      this.setState({
        loading: false,
        companies,
        customColumns: getUserColumnsService(),
      });
    } catch (error) {
      this.setState({loading: false, companies: []});
    }
  };

  filterCompanies = ({filter, filterValue}) =>
    this.changeFilter(filter)(filterValue);

  goToCompany = (companyId) => () =>
    this.props.navigate(companyRoute(companyId));

  setView = ({key: view}) => {
    this.setState({view});
  };

  options = () =>
    [...this.constructor.columnGroups].map(({key, name}) => ({
      key,
      label: name,
    }));

  getColumnGroup = ({view = this.state.view} = {}) => {
    return [...this.constructor.columnGroups].find(({key}) => key === view);
  };

  getColumns = () => {
    const {companies, customColumns, view} = this.state;
    const columnGroup = this.getColumnGroup();
    const additionalColumns =
      view === customColumnGroup.key
        ? getSelectedColumns({
            selectedColumns: customColumns,
            suppliers: companies,
          })
        : [];
    return (
      !!columnGroup
        ? [
            ...columnGroup.columns,
            ...additionalColumns,
            ...columnGroup.dynamicColumns(companies),
          ]
        : []
    ).map((column) =>
      column.key === 'primaryindustry'
        ? {
            ...column,
            filters: [...this.compiledFilterOptions.industries].map(
              (filterOption) => ({...filterOption, text: filterOption.label})
            ),
            onFilter: (filterValue, entry) =>
              entry?.Firmographic?.PrimaryIndustry === filterValue,
          }
        : column
    );
  };

  changeFilter = (key) => (value) => {
    if (key === 'collection') {
      const {collections, loadingCollection} = this.props;
      const collection = [...collections].find(({id}) => id === value);
      if (!!collection || loadingCollection)
        collectionSelectedEvent.publish(collection);
    }
    this.setState({filters: {...this.state.filters, [key]: value}});
  };

  resetFilters = () =>
    this.setState({filters: {collection: this.state?.filters?.collection}});

  companies = () => {
    const {collectionsData, tags} = this.props;
    const {companies, filters, search} = this.state;
    const parsedFilters = Object.entries(filters)
      .filter(([key, value]) => !!value && !!value?.length)
      .map(([key, value]) => {
        const companyFilter = [...Object.values(listCompanyFilters())].find(
          (companyFilter) => companyFilter.filter === key
        );
        return !!companyFilter ? {...companyFilter, filterValue: value} : null;
      })
      .filter((companyFilter) => !!companyFilter);

    const parsedCompanies = !!parsedFilters.length
      ? companies.filter((company) =>
          parsedFilters.every((companyFilter) =>
            companyFilter.fn({
              entry: company,
              filterValue: companyFilter.filterValue,
              suppliers:
                collectionsData?.[companyFilter.filterValue]?.SupplierIds || [],
              tags,
            })
          )
        )
      : companies;
    return !!search.trim().length
      ? parsedCompanies.filter((company) =>
          company?.CompanyName?.toLowerCase?.()?.includes?.(
            search.toLowerCase()
          )
        )
      : parsedCompanies;
  };

  changeCustomColumns = (customColumns) => {
    this.setState({customColumns});
    saveUserColumnsService({customColumns});
  };

  onSearch = (e) => this.setState({search: e.target.value});

  getTableSize = () => {
    const {customColumns, view, companies} = this.state;
    const columnGroup = this.getColumnGroup();
    return (
      (view === customColumnGroup.key
        ? {
            x:
              getSelectedColumns({
                selectedColumns: customColumns,
                suppliers: companies,
              })?.length * 300 || null,
          }
        : columnGroup?.size) || null
    );
  };

  render() {
    const {loadingCollection} = this.props;
    const {companies, customColumns, loading, view, filters, search} =
      this.state;

    return loading || loadingCollection ? (
      <FullScreenLoader />
    ) : (
      <CompaniesTable
        availableColumns={getAllAvailableColumnOptions({suppliers: companies})}
        columns={this.getColumns()}
        compiledFilterOptions={this.compiledFilterOptions}
        customColumns={customColumns}
        dataSource={this.companies()}
        filterOptions={listCompanyFilters()}
        filters={filters}
        numberOfSuppliers={companies.length}
        onCustomColumn={this.changeCustomColumns}
        onFilter={this.changeFilter}
        onFilterReset={this.resetFilters}
        onSearch={this.onSearch}
        onView={this.setView}
        search={search}
        showCustomColumnsSelector={view === customColumnGroup.key}
        tableSize={this.getTableSize()}
        view={view}
        viewOptions={this.options()}
      />
    );
  }
}

export default connect((state) => ({
  collection: state.collection.collection,
  collections: state.collection.collections,
  collectionsData: state.collection.collectionsData,
  customData: state.tenant.customData,
  loadingCollection: state.collection.loadingCollection,
  suppliers: state.supplier.suppliersForSearch,
  tags: state.tag.tags,
  tenantData: state.dashboard.tenantData,
}))(withNavigationHOC(subscriptionHOC(CompaniesTableContainer)));
