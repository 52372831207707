// company components
import CompanyDefaultDataColumn from '../../../components/CompaniesTableColumns/staticColumns/CompanyDefaultDataColumn/CompanyDefaultDataColumn';

// local lib
import generateColumn from '../../../../table/lib/generateColumn.lib.table';

export default generateColumn({
  title: 'Active Engagements',
  componentProps: ({company}) => ({
    children: company?.ManagementDueDiligence?.ActiveEngagements || '-',
  }),
  onCell: () => ({
    colSpan: 0,
  }),
  Component: CompanyDefaultDataColumn,
  sorter: true,
});
