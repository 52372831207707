// company components
import HighlightInfo from '../../../components/HighlightInfo/HighlightInfo';

// local lib
import generateColumn from '../../../../table/lib/generateColumn.lib.table';

export default generateColumn({
  title: 'Overall Environmental Impact',
  componentProps: ({company}) => ({
    children: company?.ESGCSR?.OverallEnvironmentalImpact || '-',
  }),
  Component: HighlightInfo,
  sorter: (a, b) => {
    return (
      Number(a?.ESGCSR?.OverallEnvironmentalImpact || 0) -
      Number(b?.ESGCSR?.OverallEnvironmentalImpact || 0)
    );
  },
});
