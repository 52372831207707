// collection api
import listCollectionsApi from '../api/list.api.collection';

const loadCollectionsService = async ({
  listCollections = listCollectionsApi,
  query = {},
} = {}) => {
  try {
    const collections = await listCollections(query);
    return collections;
  } catch (error) {
    return {data: []};
  }
};

export default loadCollectionsService;
