// company components
import CompanyDefaultDataColumn from '../../../components/CompaniesTableColumns/staticColumns/CompanyDefaultDataColumn/CompanyDefaultDataColumn';

// local lib
import generateColumn from '../../../../table/lib/generateColumn.lib.table';

export default generateColumn({
  title: 'No. of Special Interest Persons (SIPs)',
  componentProps: ({company}) => ({
    children: company?.Sanctions?.['No.ofSpecialInterestPersons(SIPs)'] || '-',
  }),
  Component: CompanyDefaultDataColumn,
  sorter: (a, b) => {
    return (
      Number(a?.Sanctions?.['No.ofSpecialInterestPersons(SIPs)'] || 0) -
      Number(b?.Sanctions?.['No.ofSpecialInterestPersons(SIPs)'] || 0)
    );
  },
});
