// company components
import CompanyDefaultDataColumn from '../../../components/CompaniesTableColumns/staticColumns/CompanyDefaultDataColumn/CompanyDefaultDataColumn';

// local lib
import generateColumn from '../../../../table/lib/generateColumn.lib.table';

export default generateColumn({
  title: 'No. of Linked Special Interest Entities',
  componentProps: ({company}) => ({
    children: company?.Sanctions?.['No.ofLinkedSpecialInterestEntities'] || '-',
  }),
  Component: CompanyDefaultDataColumn,
  sorter: (a, b) => {
    return (
      Number(a?.Sanctions?.['No.ofLinkedSpecialInterestEntities'] || 0) -
      Number(b?.Sanctions?.['No.ofLinkedSpecialInterestEntities'] || 0)
    );
  },
});
