import styled from 'styled-components';

// constants
const SIZE = '60px';

const ImageContainer = styled.div`
  width: ${SIZE};
  min-width: ${SIZE};
  max-width: ${SIZE};
`;

export default ImageContainer;
